import SliderFull from "./SliderFull";
import {useEffect } from 'react';
import Slider from "./Slider";
import Carousels from "./Carousels";
import CategoryCarousels from "./CategoryCarousels";
import SliderSlick from "./SlickSlider";
import ReactGA from "react-ga4";
import VerticalCategoryCarousels from "./VerticalCategoryCarousels";
import ReactPixel from "react-facebook-pixel";

const HomePage = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "HOME"});
        ReactPixel.track(`HOME`);
    }, []);

    return (
        <>
            <SliderFull category_id={"c7447fdd14fc47eea82ebf855497caf3"}/> {/* Novedades */}

            <VerticalCategoryCarousels parent_category={"62fd04f7eafa4c18b20f925c53b3e9b5"} category_name={"homeContentsPerAxis"}
                               key={"62fd04f7eafa4c18b20f925c53b3e9b5"}/>  {/* EJES */}
            
            <Carousels category_id={"facf62de498241cb89fe7f265103be88"} category_name={"homeMostViewed"}/> {/* LO MAS VISTO */}
            <SliderSlick category_id={"7e9ac6dc5322422e802710f2c489f44a"} category_name_for_show={"homeNewest"} header={true}/>

            <CategoryCarousels parent_category={"e3704e02c544488f858a5459816b8862"} category_name={"homeVoices"}
                               key={"e3704e02c544488f858a5459816b8862"}/> {/*Las Voces*/}

            <Carousels category_id={"ead8aea8742e412aa782c0fb37b0faf6"} category_name={"wellbeingWeek"}/>
            <Carousels category_id={"65a690c8bfd84e60b8a478f5716bd4c4"} category_name={"wellbeing2024"}/>
            <Carousels category_id={"8094b7538ee547c69e9f6bd7206a67b4"} category_name={"wellbeingB"}/>
            <Carousels category_id={"77551789163649c6a8d18bb344d602ae"} category_name={"wellbeing"}/>
            <body><script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=4287c694-df35-41cf-90f8-d34e74c5f34a"> </script></body>


        </>
    )
}

export default HomePage;
