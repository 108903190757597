const LoaderSpinner = () => {

    return (
        <div className="row text-center">
            <div className="col">
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default LoaderSpinner;