import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import {config} from "../../../config";
import LoaderSpinner from 'utils/LoaderSpinner';
import SlideItem from "./SlideItem";
import OwlCarousel from "react-owl-carousel";
import {FormattedMessage} from "react-intl";

/** MUESTRA LAS CATEGORIAS HIJOS **/

const CategoryCarousels = ({parent_category, category_name}) => {
    const [vodCategories, setVodCategories] = useState([]);
    const [vodList, setVodList] = useState([]);

    const { response, loading, error } = useAxios({
        method: 'get',
        url: `/aos-category/parent_id/${parent_category}`
    });

    useEffect(() => {
        if (response !== null) {
            setVodCategories(response.category);
        }
    }, [response]);

    const categoriesOrder = (a, b) => {
        if (a.order_by < b.order_by) {
            return -1
        }
        if (a.order_by > b.order_by) {
            return 1
        }
        return 0
    }

    const Slider = () => {
        vodCategories.sort(categoriesOrder)

        const Slides = vodCategories.map((vod) =>
            <SlideItem key={vod.category_id} {...vod} />
        );

        return (
            <OwlCarousel className='owl-carousel owl-loaded owl-drag' {...config.owlCarousel.carousel.options}>
                {Slides}
            </OwlCarousel>
        )
    }

    return (
        <>
            {loading ? (
                <LoaderSpinner />
            ) :  (
                <section className="pt-0 pb-0">
                    <div className="container space">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <h4 className="gen-heading-title text-uppercase">
                                    <FormattedMessage
                                        id={category_name}
                                    /></h4>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="gen-style-2">
                                    {loading ? ( <LoaderSpinner />) :  (<Slider /> )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>   
        
    )
}

/*
*/ 

export default CategoryCarousels;
