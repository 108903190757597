import {useState, useEffect, useRef, useContext} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {config} from "../../../../config";
import useAxios from "../../../../hooks/useAxios";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../../../contexts/AppContext";

const AxiosItem = () => {
    const dataLang = useContext(AppContext)
    const [axiosCategories, setAxiosCategories] = useState([]);

    const {response, loading, error} = useAxios({
        // /aos-vod/category?tenant_id=${config.tenant_id}&category_id=${config.posters_category_id}
        method: 'get',
        url: `aos-category/parent_id/${config.axis_id}`
    });

    useEffect(() => {
        if (response !== null) {
            setAxiosCategories(response.category);
        }
    }, [response]);


    const DropdownList = () => {
        const a_item = axiosCategories.map(data =>
            <NavLink className="dropdown-item"  to={`/category/${data.category_id}`}
               key={`dropdown-item-${data.category_id}`}>
                { dataLang.langSpanish ? data.category_name : data.description }
            </NavLink>
        );
        return (
            <>
                {a_item}
            </>
        )
    }


    return (
        <>
            <div className="btn-group dropdown d-none d-lg-block">
                <button type="button" className="btn dropdown-toggle button_font_white" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton">
                    <FormattedMessage
                        id="navAxios"
                        defaultMessage="Ejes"
                    />
                </button>
                <div className="dropdown-menu" id="dropdown-menu" key={"dropdown-menu-1234"}
                     aria-labelledby="dropdownMenuButton">
                    <DropdownList/>
                </div>
            </div>
            <div className="d-lg-none">
                <DropdownList />
            </div>
        </>
    )

}

export default AxiosItem;
