import { Link } from 'react-router-dom';
import { config } from 'config';
import heroImage from 'assets/images/background/heroImage.jpg';

 const Breadcrumb = ({title, url=heroImage}) =>{
     if(url === undefined){
         url = heroImage
     }
    return (
        <div className="gen-breadcrumb mt-4" style={{ backgroundImage: `url(${url})` }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <nav aria-label="breadcrumb">
                            <div className="gen-breadcrumb-title">
                                <h1>
                                    {title}
                                </h1>
                            </div>
                            <div className="gen-breadcrumb-container">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={'/'}>
                                            <i className="fas fa-home mr-2"></i>Home
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active">{title}</li>
                                </ol>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
 }

 export default Breadcrumb;
