import { Link } from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useContext} from "react";
import {AppContext} from "contexts/AppContext";

const WidgetCenterRight = () => {
    const context = useContext(AppContext)
    return (
        <div className="widget">
            <h4 className="footer-title"><FormattedMessage
                id="footerInformation"
                defaultMessage="Información"
            /></h4>
            <div className="menu-explore-container">
                <ul className="menu">
                    <li className="menu-item">
                        <Link className="" to={'/sign-up'}>
                            <FormattedMessage
                                id="subscribeLow"
                                defaultMessage="Suscribete"
                            /></Link>
                    </li>

                </ul>

                    <li className="purple-text">
                        <Link className="footer-color-white" to={'/privacy-policy-week'}>
                            <FormattedMessage
                                id="privacyRedirection"
                                defaultMessage="Aviso de Privacidad de la Semana del Bienestar Integral"
                            /></Link>
                    </li>

            </div>
        </div>
    )
}

export default WidgetCenterRight;
