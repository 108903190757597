import {useContext, useEffect, useState} from 'react';
import useAxios from 'hooks/useAxios';
import {config} from "../../config";
import Breadcrumb from 'utils/Breadcrumb';
import {AppContext} from "../../contexts/AppContext";

const BreadcrumbWrapper = ({id}) => {
    const dataLang = useContext(AppContext)
    const [breadcrumbTitle, setBreadcrumbTitle] = useState([]);
    const [breadcrumbUrl, setBreadcrumbUrl] = useState(undefined);
    
    const { response, loading, error } = useAxios({
        method: 'get',
        url: `aos-category/${id}`
    });

    useEffect(() => {
        if (response !== null) {
            let lang =  dataLang.langSpanish ? response.category.category_name : response.category.description
            setBreadcrumbTitle(lang);
            if (response.category.banner !== undefined){
                setBreadcrumbUrl(`${config.cloudfront_image_category}/${response.category.banner}`)
            }
        }
    }, [response]);

    return (
        <div>
            { loading ? null : (<Breadcrumb title={breadcrumbTitle} url={breadcrumbUrl} />) }
        </div>
    )
}

export default BreadcrumbWrapper;
