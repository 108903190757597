//import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import HomePage from 'views/home';
import TeaserPage from 'views/teaser';
import CategoryPage from 'views/category';
import SearchPage from 'views/search';
import SignUp from "../views/sign-up";
import RecoveryPassword from "../views/recovery-password";
import CheckEmail from "../views/check-email";
import ThankYou from "../views/thank-you";
import PrivacyPolicyWeek from "../views/privacy-policy-week";
import RecoveryPasswordConfirmation from "../views/recovery-password-confirmation";

/*Necesita ser gestionado con Suspense*/ 
 //const HomePage = lazy(() => import('views/home'));
// const TeaserPage = lazy(() => import('views/teaser'));
// const WatchPage = lazy(() => import('views/watch'));
// const AuthLogin = lazy(() => import('views/authentication/login'));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/teaser/:type/:id',
            element: <TeaserPage />
        },
        {
            path: '/sign-up', // '/live',
            element: <SignUp />
        },
        {
            path: '/category/:id',
            element: <CategoryPage />
        },
        {
            path: '/recovery-pass', // '/vod',
            element: <RecoveryPassword />
        },
        {
            path: '/recovery-pass-confirmation',
            element: <RecoveryPasswordConfirmation />
        },
        {
            path: '/check-email', // '/vod',
            element: <CheckEmail />
        },
        {
            path: '/search/:data',
            element: <SearchPage />
        },
        {
            path: '/search',
            element: <SearchPage />
        },
        {
            path: '/thank-you',
            element: <ThankYou />
        },
        {
            path: '/privacy-policy-week',
            element: <PrivacyPolicyWeek />
        }
    ]
};

export default MainRoutes;
