import PropTypes from 'prop-types';
import React, {createContext, useEffect, useReducer} from 'react';
import {CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails} from 'amazon-cognito-identity-js';

// reducer - state management
import {LOGIN, LOGOUT, FORGOTPASS} from 'store/actions';
import accountReducer from 'store/accountReducer';

import {AWS_API} from 'config';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
    } else {
        localStorage.removeItem('serviceToken');
    }
};

const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({children}) => {

    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const loggedInUser = userPool.getCurrentUser();
                if (loggedInUser) {
                    loggedInUser.getSession(function (err, session) {
                        if (err) {
                            console.log('No hay sesión de usaurio: ', err.message);
                            dispatch({
                                type: LOGOUT
                            });
                        } else {
                            const serviceToken = window.localStorage.getItem('serviceToken');
                            const userEmail = session.idToken.payload.email;
                            const userSub = session.idToken.payload.sub;
                            setSession(serviceToken);
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: true,
                                    user: {
                                        email: userEmail,
                                        sub: userSub,
                                        session: serviceToken
                                    }
                                }
                            });
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error('error', err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const forgotPassword = async (email) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        return new Promise(
            (resolve, reject) => {
                user.forgotPassword({
                        onSuccess: (data) => {
                            console.log(data)
                            resolve(true)
                        },
                        onFailure: (_err) => {
                            console.log(_err)
                            reject(_err)
                        },
                    }
                )
            }
        )

    }

    const recoveryPassword = async (email, code, password) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        return new Promise(
            (resolve, reject) => {
                user.confirmPassword(code, password, {
                    onSuccess: (data) => {
                        resolve(true)
                    },
                    onFailure: (_err) => {
                        reject(_err)
                    },
                })

            }
        )

    }
    const checkEmail = async (code, email) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        return new Promise((resolve, reject) => {
            usr.confirmRegistration(code, false, function (err, result) {
                if (err) {
                    reject(err)
                } else {
                    resolve(true)
                }
            });
        });

    }

    const requestCode = async (email) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        return new Promise((resolve, reject) => {
            usr.getAttributeVerificationCode(email, {
                onSuccess: (data) => {
                    resolve(true)
                },
                onFailure: (_err) => {
                    reject(_err)
                }
            });
        });

    }

    const newPasswordChallenge = async (email, password, newPassword) => {
        console.log(localStorage.getItem("userAttributes"))
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        const authData = new AuthenticationDetails({
            Username: email,
            Password: password
        });
        return new Promise((resolve, reject) => {
            usr.authenticateUser(authData, {
                onSuccess: (session) => {
                    console.log(session)
                    setSession(session.getAccessToken().getJwtToken());
                    const userEmail = session.idToken.payload.email;
                    const userSub = session.idToken.payload.sub;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                email: userEmail,
                                sub: userSub
                            }
                        }
                    });
                    resolve(userEmail);
                },
                onFailure: (_err) => {
                    console.log(_err)
                    reject(_err)

                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    usr.completeNewPasswordChallenge(newPassword, requiredAttributes, {
                        onSuccess: (session) => {
                            setSession(session.getAccessToken().getJwtToken());
                            const userEmail = session.idToken.payload.email;
                            const userSub = session.idToken.payload.sub;
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: true,
                                    user: {
                                        email: userEmail,
                                        sub: userSub
                                    }
                                }
                            });
                            resolve(userEmail);
                        },
                        onFailure: (_err) => {
                            console.log(_err)
                            reject(_err)

                        }
                    });
                }
            });

        });
    }

    const login = async (email, password) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        const authData = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        return new Promise((resolve, reject) => {
            usr.authenticateUser(authData, {
                onSuccess: (session) => {
                    setSession(session.getAccessToken().getJwtToken());
                    console.log(session.accessToken.jwtToken)
                    const userEmail = session.idToken.payload.email;
                    const userSub = session.idToken.payload.sub;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                email: userEmail,
                                sub: userSub,
                                session: session.accessToken.jwtToken
                            }
                        }
                    });
                    resolve(userEmail);
                },
                onFailure: (_err) => {
                    console.log(_err)
                    reject(_err)

                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    console.log('newPasswordRequired');
                    reject('newPasswordRequired')
                }
            });
        });
    };

    const createUser = (email, password, phone, name, lastName, callback) => {

        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,

            }),
            new CognitoUserAttribute({
                Name: 'phone_number',
                Value: phone,

            }),
            new CognitoUserAttribute({
                Name: 'name',
                Value: name + " " + lastName,
            }),
        ]

        // Username must be unique in a pool, and cant be a valid email format
        // To log in with email, make sure it is set as an alias attribute in Cognito
        // More info: http://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html#user-pool-settings-usernames

        userPool.signUp(email, password, attributeList, null, callback)
    }

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setSession(null);
            loggedInUser.signOut();
            dispatch({type: LOGOUT});
        }
    };

    return (
        <AWSCognitoContext.Provider
            value={{
                ...state,
                createUser,
                login,
                logout,
                forgotPassword,
                recoveryPassword,
                checkEmail,
                requestCode,
                newPasswordChallenge
            }}>
            {children}
        </AWSCognitoContext.Provider>
    );
};

AWSCognitoProvider.propTypes = {
    children: PropTypes.node
};

export default AWSCognitoContext;
