export const config = {
    tenant_id: 'tecmilenio',
    posters_category_id: '8e6c242d4ca841409c68a1e4079ba4ad',
    axis_id: "62fd04f7eafa4c18b20f925c53b3e9b5",
    newest: "",
    voices: "e3704e02c544488f858a5459816b8862",
    viewest: "facf62de498241cb89fe7f265103be88",
    live: "68de4b0d55a740b4aade79a570a8e859",
    api_endpoint: 'https://uyg7bq6eo6.execute-api.us-east-1.amazonaws.com/production',
    search_endpoint : 'https://wolifvtqpe.execute-api.us-east-1.amazonaws.com/prod',
    cloudfront_image_category: 'https://d2sdk1g41rkzfz.cloudfront.net',
        cloudfront_url: 'https://d2sdk1g41rkzfz.cloudfront.net',
        cloudfront_hls: 'https://d2sdk1g41rkzfz.cloudfront.net/hls',
    owlCarousel: {
        full_slider: {
            options: {
                items: 1,
                rewind: true,
                autoplay: true,
                loop: true,
                margin: 30,
                nav: true,
                autoplayTimeout: 15000
            }
        },
        slider: {
            options: {
                items: 4,
                rewind: true,
                autoplay: true,
                loop: true,
                margin: 30
            }
        },
        carousel: {
            options: {
                items: 5,
                margin: 30,
                responsive:{
                    0:{
                        items:1,
                        nav:true,
                        margin: 30,
                        rewind: true,
                    },
                    700:{
                        items:2,
                        nav:true,
                        margin: 30,
                        rewind: true,
                    },
                    1200:{
                        items:3,
                        nav:true,
                        margin: 30,
                        rewind: true,
                    },
                    1600:{
                        items:4,
                        nav:true,
                        margin: 30,
                        rewind: true,
                    }
                }
            },
            vertical: {
                items: 5,
                margin: 30,
                responsive:{
                    0:{
                        items:1,
                        nav:true,
                        margin: 30,
                        rewind: true,
                    },
                    700:{
                        items:3,
                        nav:true,
                        margin: 30,
                        rewind: true,
                    },
                    1200:{
                        items:4,
                        nav:true,
                        margin: 30,
                        rewind: true,
                    },
                    1600:{
                        items:5,
                        nav:true,
                        margin: 30,
                        rewind: true,
                    }
                }
            }
        },
        simple_teaser: {
            options: {
                items: 1,
                rewind: false,
                autoplay: false,
                loop: false,
                margin: 30,
                nav: false
            }
        },
    },
    consts: {
        'NO_ITEMS': 0,
        'YEAR': 'Año',
        'CLASSIFICATION': 'Clasificación',
        'DIRECTOR': 'Director',
        'LENGTH': 'Duración',
        'CAST':'Elenco',
        'GENRE': 'Género',
        'LANGUAGE': 'Idioma'
    },
    login_modal_wait_time: 5000,
    carrousel_max_items: 8,
    cache_expiration_time_in_secs: 60
};

export const AWS_API = {
    poolId: 'us-east-1_2zyCIdJlL', //  QA  'us-east-1_MpUzg0SGd',   // PROD 'us-east-1_2zyCIdJlL'
    appClientId: '5r3bcurg888e1pokg4onrkmjp' //  QA '15eh4ninbnj16f8s1omh21foar'  //PROD  '5r3bcurg888e1pokg4onrkmjp'
};
