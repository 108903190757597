import useAuth from "../../hooks/useAuth";
import {useForm} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import {config} from "../../config";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../contexts/AppContext";
import ReactPixel from "react-facebook-pixel";

const CheckEmail = () => {

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: `/check-email`, title: `Wellbeing360 - VERIFY-MAIL`});
        ReactPixel.track(`VERIFY-MAIL`);
    }, []);

    const context = useContext(AppContext)
    let navigate = useNavigate();
    const {checkEmail, requestCode} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm();

    const OnSubmit = async (data) => {
        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!emailRegex.test(data.userName)) {
            const error_lang = context.langSpanish ? "* Email invalido" : "* Invalid Email"
            setError(error_lang)
            return
        } else {
            setError(null)
        }
        setLoading(true);
        setError('');
        try {
            await checkEmail(data.verificationCode, data.userName)
            setTimeout(() => {
                setLoading(false)
                const message_lang = context.langSpanish ? "Email Verificado" : "Verified email"
                toast(
                    (t) => (
                        <div className="">
                            <h4 className="pToastLogOut">{message_lang}</h4>
                            <button className="btn button-purple buttonToast" onClick={() => {
                                toast.dismiss(t.id);
                                navigate(`/login`)
                            }}>Ok
                            </button>
                        </div>
                    ), {duration: 30000,}
                );
            }, config.login_modal_wait_time);
        } catch (err) {
            setLoading(false)
            const error_lang = context.langSpanish ? "Ocurrio un error al verificar el email\n" +
                "Por favor verifica la información" : "An error occurred while verifying the email\n" +
                "Please verify the information"
            setError(error_lang)
            toast(
                (t) => (
                    <div className="">
                        <h4 className="pToastLogOut">{error_lang}
                        </h4>

                        <button className="btn button-purple buttonToast" onClick={() => {
                            toast.dismiss(t.id);
                        }}>Ok
                        </button>
                    </div>
                ), {duration: 10000,}
            );
            console.log(err)
        }
    }
    // TODO agregar solicitud de nuevo codigo
    const RequestCodeVerification = async (data) => {
        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!emailRegex.test(data.userName)) {
            setError("* Email invalido")
            return
        } else {
            setError(null)
        }
        setLoading(true);
        setError('');
        try {
            await requestCode(data.userName)
            setTimeout(() => {
                setLoading(false)
                toast(
                    (t) => (
                        <div className="">
                            <h4 className="pToastLogOut">Codigo Enviado, Por favor revise su bandeja de entrada</h4>
                            <button className="btn button-purple buttonToast" onClick={() => {
                                toast.dismiss(t.id);
                                navigate(`/login`)
                            }}>Ok
                            </button>
                        </div>
                    ), {duration: 30000,}
                );
            }, config.login_modal_wait_time);
        } catch (err) {
            setLoading(false)
            toast(
                (t) => (
                    <div className="">
                        <h4 className="pToastLogOut">Ocurrio un error al solicitar el código <br/>
                            Por favor verifica la información
                        </h4>

                        <button className="btn button-purple buttonToast" onClick={() => {
                            toast.dismiss(t.id);
                        }}>Ok
                        </button>
                    </div>
                ), {duration: 15000,}
            );
            console.log(err)
        }
    }

    return (
        <>
            <section className="position-relative pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <form id="pms_recover_password_form" className="pms-form"
                                      onSubmit={handleSubmit(OnSubmit)}>
                                    <h4><FormattedMessage
                                        id="checkTitle"
                                        defaultMessage="Verificar email"
                                    /></h4>

                                    <p className="font-weight-bold">
                                        <FormattedMessage
                                            id="checkDescription"
                                            defaultMessage="Por favor ingresa tu correo electrónico y tu código de verificación"
                                        />
                                    </p>
                                    <ul className="pms-form-fields-wrapper pl-0 mb-4">
                                        <li className="pms-field">
                                            <label htmlFor="pms_username_email"><FormattedMessage
                                                id="checkEmail"
                                                defaultMessage="Correo electrónico"
                                            /></label>
                                            <input  {...register("userName", {required: true})}
                                                    id="pms_username_email_input" type="text"
                                                    defaultValue=""/>
                                        </li>
                                        <li className="pms-field">
                                            <label htmlFor="pms_verification_code">
                                                <FormattedMessage
                                                id="checkCode"
                                                defaultMessage="Codigo de Verificación"
                                            /></label>
                                            <input  {...register("verificationCode", {required: true})}
                                                    id="pms_verification_code_input" type="text"
                                                    defaultValue=""/>
                                        </li>
                                        <li className="pms-field pms-use pms-field pms-field-subscriptions">
                                            {error ? (
                                                <div className="login-error">
                                                    {error}
                                                </div>
                                            ) : null
                                            }
                                        </li>
                                    </ul>
                                    {loading ?
                                        (<div className="spinner-border text-light" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>) :
                                        (
                                            <button type="submit" onSubmit={handleSubmit(OnSubmit)}>
                                                <FormattedMessage
                                                    id="checkButton"
                                                    defaultMessage="Verificar Correo"
                                                />
                                            </button>
                                        )}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CheckEmail
