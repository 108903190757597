import './utils/jqueryLoader.js'
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};
//Anterior
//ReactPixel.init('864551054974472', options);
//Nueva
ReactPixel.init('973002320441100', options);

//Anterior Google Analitics Tag 09/10/2023, Actuliza Marco. 
//ReactGA.initialize("G-D1G6CRGXPH");
ReactGA.initialize("GTM-K5PNLWC3");

ReactGA.initialize([
    {
        trackingId: "GTM-K5PNLWC3",
        gaOptions: {
            'debug_mode':false
        }, // optional
        gtagOptions: {
            'debug_mode':false
        }, // optional
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>  
);
