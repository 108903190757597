import {useEffect, useState} from 'react';
import useAxios from 'hooks/useAxios';
import TeaserLiveBody from './TeaserLiveBody';
import LoaderSpinner from 'utils/LoaderSpinner';
import {config} from 'config';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const LiveTeaser = ({id}) => {

    const [liveItem, setLiveItem] = useState([]);
    const [isEmptyLiveItem, setIsEmptyLiveItem] = useState(true);

    const {response, loading, error} = useAxios({
        method: 'get',
        url: `/aos-vod?stream_id=${id}&tenant_id=${config.tenant_id}`
    });

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: `/teaser/live/${id}`, title: `Wellbeing360 - EVENT-LIVE`});
        ReactPixel.track(`EVENT-LIVE`);
    }, []);

    useEffect(() => {
        if (response !== null) {
            console.log('response', response.stream);
            setLiveItem(response.stream);
            setIsEmptyLiveItem(false);
        }
    }, [response]);

    return (
        <>
            {(isEmptyLiveItem) ? (
                <LoaderSpinner/>
            ) : (
                <>
                    <div id="bitmovin-player-container" className="gen-btn-container button-1">
                        <div className='row my-4 pl-3'>
                            <Link to="/" className="gen-button small">
                                <i aria-hidden="true" className="ion ion-arrow-return-left"></i> <span
                                className="text"><FormattedMessage
                                id="buttonGoBack"
                                defaultMessage="Regresar"
                            /></span>
                            </Link>
                        </div>
                    </div>
                    <section className="pt-0 pb-0">
                        <div className="container-fluid px-0">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="gen-banner-movies teaser-page">
                                        <TeaserLiveBody {...liveItem} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>

            )}
        </>
    )
}

export default LiveTeaser;
