//import { lazy } from 'react';
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';

import AuthLogin from 'views/authentication/login';

// const HomePage = lazy(() => import('views/home'));
// const TeaserPage = lazy(() => import('views/teaser'));
// const AuthLogin = lazy(() => import('views/authentication/login'));

// ==============================|| MAIN ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <MinimalLayout />
    ),
    children: [
        {
            path: '/login' ,  //  '/login'
            element: <AuthLogin />
        }
    ]
};

export default LoginRoutes;
