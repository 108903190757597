import { Link } from "react-router-dom";
import logoICBI from 'assets/images/LogoICBI.png'
import logo from 'assets/images/logo-wellbeing360-Color-Blanco.png';

const LogoSection = () =>{

    return (
        <Link to={'/'} className="navbar-brand"> 
            <div className="row">
                <img src={logo} alt="Logo 360" className="img-fluid logo " />
                <img src={logoICBI} alt="Logo 360" className="img-fluid logo " />
            </div>

        </Link>
    )
}

export default LogoSection;
