import { Outlet } from 'react-router-dom';
import { Toaster } from "react-hot-toast";
import Header from "./Header";
import Loader from "./Loader";
import Footer from "./Footer";
import { ModalLoginWindow } from 'utils/ModalLoginWindow';

const MainLayout = () => {
    return (
        <>
            <Toaster />
            <Header />
            <Outlet />
            <ModalLoginWindow />
            <Footer />
        </>
        
    )

}

export default MainLayout;
