import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import VodTeaser from './VodTeaser';
import LiveTeaser from './LiveTeaser';

const TeaserPage = () => {

    let { id, type } = useParams();
    const [teaserType, setTeaserType] = useState(''); 

    useEffect(() => {
        if (type === 'vod') {
            setTeaserType('vod');
        }
        if (type === 'live') {
            setTeaserType('live');
        }
    }, []);

    const TeaserBody = ({teaserType}) => {
        switch (teaserType) {
            case 'vod':
                return <VodTeaser id={id} />  
            case 'live':
                return <LiveTeaser id={id} />  
            default:
                return 'null';
        }
    }

    return (
        <section className="gen-section-padding-3 gen-single-movie">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-12">
                        <div className="gen-single-movie-wrapper style-1">
                            <TeaserBody teaserType={teaserType} />
                        </div>
                    </div>
                </div>
            </div>
        </section>        
    )
}

export default TeaserPage;