import { useContext, useEffect } from "react";
import { AppContext } from "contexts/AppContext";
import LoginForm from 'views/authentication/login/LoginForm';

import $ from 'jquery';

const actionCloseModal = {
    type: '[MODAL] Close Modal',
    payload: ''
}

export const ModalLoginWindow = () => {
    
    const { modalLoginWindow, dispatchModalWindow } = useContext( AppContext );
    
    useEffect(() => {
      if(modalLoginWindow.isOpen){
        $('#loginModal').modal('show');
      } 
      
    }, [modalLoginWindow]);

    useEffect(() => {
        $('#loginModal').on('hidden.bs.modal', function (e) {
            dispatchModalWindow(actionCloseModal);
        });
    }, [])
    
    
    const handleCloseModal = () => {
        $('#loginModal').modal('hide');
        dispatchModalWindow(actionCloseModal);
    }

    return (
        <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-body">
                    <LoginForm handleCloseModal={handleCloseModal} />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
        </div>
    )
}
