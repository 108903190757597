

const DropdownItem = ({country, code}) => {

    return (
        <option value={code}>{`${country}   ${code}`}</option>
    )
}

export default DropdownItem;
