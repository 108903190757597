import { Outlet } from 'react-router-dom';
import { Toaster } from "react-hot-toast";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <Toaster />
        <Outlet />
    </>
);

export default MinimalLayout;
