
import MenuItems from './MenuItems';
import SearchItem from './SearchItem';
import ProfileItem from './ProfileItem';
import SignUpItem from './SignUpItem';

const NavbarSection = () => {

    return (
        <>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <MenuItems />
            </div>
            <div className="gen-header-info-box">
                {/*<SearchItem />*/}

            </div>
            <button 
                className="navbar-toggler" 
                type="button" 
                data-toggle="collapse"
                data-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent"
                aria-expanded="false" 
                aria-label="Toggle navigation">
                <i className="fas fa-bars"></i>
            </button>
        </>
    )
}

export default NavbarSection;
