import { useState, useEffect } from 'react';
import axios from 'axios';

import { config } from 'config';
import moment from 'moment';

const useAxiosSearch = ({ url, method, body = null }) => {

    axios.defaults.baseURL = config.search_endpoint;
    
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!fetchDataFromLocalStorage(body)){
            fetchData();
        }
    }, [method, url, body]);

    const fetchData = () => {
        axios[method](url, JSON.parse(body))
            .then((res) => {
                console.log(res.data);
                setResponse(res.data);
                setDataToLocalStorage(url, res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchDataFromLocalStorage = (url) => {
        let localItem = window.localStorage.getItem(url);
        if(localItem){
            localItem = JSON.parse(localItem);
            const cacheExpirationDate = localItem.expiration;
            const nowDate = moment().unix();
            if(nowDate > cacheExpirationDate){
                window.localStorage.clear();
                return false;
            }
            setResponse(localItem.data);
            setLoading(false);
            return true;
        }
        return false;
    }

    const setDataToLocalStorage = (url, data) => {
        const itemToStore = {
            data,
            expiration: moment().add(config.cache_expiration_time_in_secs, 'seconds').unix()
        }
        window.localStorage.setItem(url, JSON.stringify(itemToStore));
    }

    return { response, error, loading };
};

export default useAxiosSearch;
