import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import useAxios from 'hooks/useAxios';
import LoaderSpinner from 'utils/LoaderSpinner';
import BreadcrumbWrapper from './BreadcrumbWrapper';
import GridItem from './GridItem';
import { config } from 'config';
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";


const CategoryPage = () => {
    let { id } = useParams();
    const [vodList, setVodList] = useState([]);
    const [breadcrumbId, setBreadcrumbId] = useState([]);
    const [isEmptyVodList, setIsEmptyVod] = useState(true);

    const { response, loading, error } = useAxios({
        method: 'get',
        url: `aos-vod/category/${id}?category_id=${id}&tenant_id=tecmilenio`
    });

    const categoriesOrder = (a, b) => {
        if (a.order_by < b.order_by) {
            return -1
        }
        if (a.order_by > b.order_by) {
            return 1
        }
        return 0
    }

    useEffect(() => {
        if (response !== null) {
            console.log(response)
            let list = []
            if(response.category_list.length > config.consts.NO_ITEMS){
                for (const video of response.category_list) {
                    if (video.public_see === 1){
                        list.push(video)
                    }
                }
                setVodList(list);
                setBreadcrumbId(id)
                setIsEmptyVod(false);
            }

            ReactGA.send({ hitType: "pageview", page: `/category/${id}`, title:`Wellbeing360 - CATEGORY-${response.category_name}` });
            ReactPixel.track(`CATEGORY-${response.category_name}`);

        }
    }, [response]);

    const Grid = () => {
        vodList.sort(categoriesOrder)
        const gridItems = vodList.map((vod) =>
            <GridItem key={vod.stream_id} {...vod} />
        ); 
        
        return (
            <section className="gen-section-padding-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                {gridItems}      
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    
    if (!isEmptyVodList) {
        return (
            <div>
                <BreadcrumbWrapper id={breadcrumbId} />
                <Grid />
            </div>
        )
    }

    return (<LoaderSpinner/>)
}

export default CategoryPage;
