import {useEffect} from "react";
import ReactGA from "react-ga4";
import imageDefault from 'assets/images/thankyou/tableta.png';
import backgroundLogin from "assets/images/LogoWellbeing360.png";
import facebook from "assets/images/facebook.png";
import twitter from "assets/images/twitter.png";
import linkedin from "assets/images/linkedin.png";
import wats from "assets/images/whatsapp.png";
import {FormattedMessage} from "react-intl";
import $ from 'jquery';
import {config} from "../../config";
import ReactPixel from 'react-facebook-pixel';

const ThankYou = () => {
    const twitterSubmit = (event) => {
        event.preventDefault();
        window.open(
            'http://twitter.com/share?url='+encodeURIComponent('https://www.wellbeing360.tv/'),
            'twitter-share-dialog','width=626,height=436'); return false;
    }

    const linkedinSubmit = (event) => {
        event.preventDefault();
        window.open(
            'https://www.linkedin.com/shareArticle?mini=true&amp;title=Evento%20virtual%20Wellbeing%20360&amp;url='
            +encodeURIComponent('https://www.wellbeing360.tv/'),
            'linkedin-share-dialog','width=626,height=436'); return false;
    }

    const facebookSubmit = (event) => {
        event.preventDefault();
        window.open(
            'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://www.wellbeing360.tv/'),
            'facebook-share-dialog','width=626,height=436'); return false;
    }

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: `/thank-you`, title: `Wellbeing360 - THANK-YOU-PAGE`});
        ReactPixel.track("THANK-YOU-PAGE")
    }, []);

    return (
        <div className="image-width" style={{backgroundImage: `url(${config.cloudfront_image_category}/images/Back_TYP.jpg)`}}>
            <div className="container">
                <div className="row margin-top-thank-you">

                    <div className="col-md-5">
                        <img src={backgroundLogin} alt="Wellbeing360" className="img-fluid logo-thank-you"/>
                        <h1 className="purple-text">
                            <FormattedMessage
                                id="thankYou_onestep"
                                defaultMessage="¡Estás a un paso de terminar tu registro!"
                            /></h1>
                        <br/>
                        <p className="whiteThankyoupage-text" >
                            <FormattedMessage
                                id="thankYouMail_p1"
                                defaultMessage="
                                    Se envío un correo de verificación, por favor
                                        verifique su bandeja de entrada"
                            />                          
                        </p>
                        <p className="whiteThankyoupage-text" >                          
                            <FormattedMessage
                                id="thankYouMail_p2"
                                defaultMessage="
                                    Se envío un correo de verificación, por favor
                                        verifique su bandeja de entrada"
                            />
                        </p>
                        <p className="whiteThankyoupage-text" >                          
                            <FormattedMessage
                                id="thankYouMail_p3"
                                defaultMessage="
                                    Se envío un correo de verificación, por favor
                                        verifique su bandeja de entrada"
                            />
                        </p>
                        <p className="whiteThankyoupage-text">
                            <FormattedMessage
                                id="thankYouRegards"
                                defaultMessage="¡Saludos!"
                            />
                        </p>
                        <div id="compartir">
                            <div className="social-links social-right">
                                <div>
                                    <span className="pr-4">
                                        <FormattedMessage
                                            id="thankYouInvite"
                                            defaultMessage=" Invita a una amiga o amigo disfrutar de nuestros videos. "
                                        /></span>
                                </div>
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2">
                                        <div onClick={(event) => {facebookSubmit(event)}}>
                                            <img src={facebook}  alt="facebook" width="24px"/>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div
                                            onClick={(event) => {twitterSubmit(event)}}>
                                            <img src={twitter} alt="twitter" width="24px"/>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div
                                            onClick={(event) => {linkedinSubmit(event)}}>
                                            <img src={linkedin} alt="linkedin" width="24px"/>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <a target="_blank" href="https://api.whatsapp.com/send?text=Evento%20virtual%20Wellbeing%20360%20https://wellbeing360.mx/">
                                            <img src={wats} alt="whatsapp" width="24px" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <img src={imageDefault} alt="Logo-Wellbeing" className="img-fluid"/>
                    </div>


                </div>
            </div>
        </div>
)
}

export default ThankYou;
