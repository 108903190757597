import { useReducer, useState } from "react"
import modalWindowReducer from 'store/modalWindowReducer'
import { IntlProvider } from "react-intl";
import { AppContext } from "./AppContext"
import EnLenguage from "./../langs/en-US.json"
import EsLenguage from "./../langs/es-MX.json"

const modal = {
    isOpen: false
}

export const AppProvider = ({ children }) => {

    const [modalLoginWindow, dispatchModalWindow] = useReducer(modalWindowReducer, modal);
    const [messages, setMessages] = useState(EsLenguage);
    const [locale, setLocale] = useState("es-MX");
    const [signUpLang, setSignUpLang] = useState("es")
    const [langSpanish, setLangSpanish] = useState(true)
    //const [locale, setLocale] = useState("es-MX");
    var data_language = localStorage.getItem("language");
    const initiallanguage = localStorage.getItem('language');

    const ChangeLang = (lang) => {
        switch (lang) {
            case "es-MX":
                setMessages(EsLenguage);
                setLocale("es-MX");
                setSignUpLang("es")
                setLangSpanish(true);
                localStorage.setItem("language", lang);
                console.log("Español")
                break;
            case "en-US":
                setMessages(EnLenguage);
                setLocale("en-US");
                setSignUpLang("en");
                setLangSpanish(false);
                localStorage.setItem("language", lang);
                console.log("Ingles")
                break;
            default:
                setMessages(EsLenguage);
                setLocale("es-MX");
                setSignUpLang("es");
                setLangSpanish(true);
                localStorage.setItem("language", lang);
                console.log("Español")
        }
    }
    
    const probeLang = () => {
        if(initiallanguage == null){
            ChangeLang("es-MX")
            localStorage.setItem("language", "es-MX");
        }else if(initiallanguage === "english"){
            localStorage.setItem("language", "en-US");
            setMessages(EnLenguage);
            setLocale("en-US");
            setSignUpLang("en");
            setLangSpanish(false);
        }else if(initiallanguage === "spanish"){
            localStorage.setItem("language", "es-MX");
            setMessages(EsLenguage);
            setLocale("es-MX");
            setSignUpLang("es");
            setLangSpanish(true);
        }
    }

    var a = probeLang();

    return (
        <AppContext.Provider value={{ modalLoginWindow, dispatchModalWindow, messages, locale, signUpLang, langSpanish, ChangeLang: ChangeLang}}>
            <IntlProvider locale={locale} messages={messages}>
                { children }
            </IntlProvider>
        </AppContext.Provider>
    )
}
