import {useEffect, useState} from 'react';
import useAuth from "hooks/useAuth";
import {Link, NavLink} from "react-router-dom";
import toast from "react-hot-toast";
import {FormattedMessage} from "react-intl";


const SignUpItem = () => {

    const {user, logout} = useAuth();
    const [isLoggedInUser, setLoggedInUser] = useState(false);

    useEffect(() => {
        if (user !== null) {
            setLoggedInUser(true);
            return;
        }
        setLoggedInUser(false);
    }, [user])

    const LoginButton = () => {
        return (
            <>
                {/* Se comenta botones de login el cliente pidio estilo similar al de Suscríbete
                    Marco Gonzalez 06/09/2023
                    
                    <Link className="d-none d-lg-block gen-account-holder-button-login" to={'login'} id="gen-user-btn">
                    <i className="fa fa-user"></i>
                    </Link>
                    <NavLink
                        className="gen-movile-holder-button-login d-lg-none"
                        to='/login'
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent">
                        <FormattedMessage
                            id="navLogin"
                            defaultMessage="Iniciar Sesión"
                        />
                    </NavLink>

                */}
                

                <NavLink
                    className="button-purple"
                    to='/login'
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent">
                    <FormattedMessage
                        id="navLogin"
                        defaultMessage="Iniciar Sesión"
                    />
                </NavLink>
            </>
        )
    }

    const LogoutButton = () => {
        return (
            <>
             {/* Se comenta botones de login el cliente pidio estilo similar al de Suscríbete
                 Marco Gonzalez 06/09/2023

             <a className="d-none d-lg-block gen-account-holder-button-login" onClick={handleLogout} id="gen-user-btn">
                    <i className="fa fa-sign-out-alt"></i>
                </a>
                <a className="gen-movile-holder-button-login d-lg-none" onClick={handleLogout}>
                    <FormattedMessage
                        id="navLogout"
                        defaultMessage="Cerrar Sesión"
                    />
                </a>

             */}
               
                <a className="button-purple" onClick={handleLogout}>
                    <FormattedMessage
                        id="navLogout"
                        defaultMessage="Cerrar Sesión"
                    />
                </a>
            </>
        )
    }

    const handleLogout = () => {
        logout();
        toast(
            (t) => (
                <div className="">
                    <h4 className="pToastLogOut"><FormattedMessage
                        id="toastLogout"
                        defaultMessage="Session Cerrada"
                    /></h4>
                    <button className="btn button-purple buttonToast" onClick={() => {
                        toast.dismiss(t.id);
                    }}>Ok
                    </button>
                </div>
            ), {duration: 15000}
        );
    }

    return (
        <div className="gen-account-holder">
            {isLoggedInUser ? <LogoutButton/> : <LoginButton/>}
        </div>
    )
}

export default SignUpItem;
