import {Link} from "react-router-dom";
import {BackgroundImage, Image} from "react-image-and-background-image-fade";

import {config} from 'config';
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";

const GridItem = ({stream_id, title, title_en, metadata, thumbnail, p}) => {
    const dataLang = useContext(AppContext)
    const [zoomLevel, setZoomLevel] = useState(100);

    useEffect(() => {
        const devicePixelRatio = window.devicePixelRatio || 1;
        const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const zoom = (windowWidth / viewportWidth) * 100 * devicePixelRatio;
        console.log(windowWidth, viewportWidth)
        setZoomLevel(zoom)
        console.log("ZOOM LEVEL", zoom)
    }, []);

    return (
        <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="gen-carousel-movies-style-3 movie-grid style-3">
                <div className="gen-movie-contain gen-movie-contain-black">
                    <div className="gen-movie-img responsive-container">
                        <img src={`${config.cloudfront_url}/${encodeURI(thumbnail)}`}
                                className="gen-movie-image" alt=""
                            />

                        <div className="gen-movie-action">
                            <Link to={`/teaser/vod/${stream_id}`} className="gen-button">
                                <i className="fa fa-play"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="gen-info-contain">
                        <div className="gen-movie-info">
                            <h6>
                                <Link
                                    to={`/teaser/vod/${stream_id}`}>{dataLang.langSpanish ? title : title_en}</Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GridItem;
