import {Link} from "react-router-dom";
import {BackgroundImage} from "react-image-and-background-image-fade";

import {config} from 'config';
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {FormattedMessage} from "react-intl";

const GridItem = ({stream_id, title, title_en, duration, metadata, thumbnail, ponente}) => {
    const dataLang = useContext(AppContext)

    const [ponenteFlag, setPonenteFlag] = useState(false);
    const [durationFlag, setDurationFlag] = useState(false);

    useEffect(() => {

        if (ponente !== undefined && ponente != null) {
            if (ponente.length > 2) {
                setPonenteFlag(true)
            }
        }
        if (duration !== undefined && duration != null) {
            if (duration.length > 2) {
                setDurationFlag(true)
            }
        }
    }, []);

    return (
        <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="gen-carousel-movies-style-3 movie-grid style-3">
                <div className="gen-movie-contain">
                    <div className="gen-movie-img">

                        <BackgroundImage
                            src={`${config.cloudfront_url}/${encodeURI(thumbnail)}`}
                            className="gen-movie-image"
                            lazyLoad
                        />

                        <div className="gen-movie-action">
                            <Link to={`/teaser/vod/${stream_id}`} className="gen-button">
                                <i className="fa fa-play"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="gen-info-contain">
                        <div className="gen-movie-info">
                            <h3>
                                <Link to={`/teaser/vod/${stream_id}`}>{dataLang.langSpanish ? title : title_en}</Link>
                            </h3>
                        </div>
                        <div className="gen-movie-meta-holder">
                            <ul>
                                <li>{durationFlag ?
                                    <>
                                        <span>
                                            <FormattedMessage
                                                id="vodDuration"
                                                defaultMessage="Duración"
                                            />: </span>
                                        <span> {duration}</span>
                                    </> :
                                    <></>
                                }</li>
                                <li>
                                    {ponenteFlag ?
                                        <>
                                            <span>
                                                <FormattedMessage
                                                    id="vodSpeaker"
                                                    defaultMessage="Ponente"
                                                />: </span>
                                            <span> {ponente}</span>
                                        </> : <></>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GridItem;
