import {config} from 'config';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useContext} from "react";
import {AppContext} from "../../../contexts/AppContext";

const SliderVertical = ({vod}) => {
    const dataLang = useContext(AppContext)
    return (
        <div className="slider-nav-contain" key={`vertical${vod.stream_id}`}>
            <div className="gen-nav-img">
                <img src={`${config.cloudfront_url}/${encodeURI(vod.thumbnail)}`}/>
            </div>
            <div className="movie-info">
                { dataLang.langSpanish ? <h3>{vod.title}</h3> : <h3>{vod.title_en}</h3> }
                <div className="gen-movie-meta-holder">
                    <ul>
                        <li>
                            <Link to={`/teaser/vod/${vod.stream_id}`} className="gen-button">
                                <FormattedMessage
                                    id="buttonWatchNow"
                                    defaultMessage="Ver Ahora"
                                />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>)
}

export default SliderVertical;
