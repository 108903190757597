import { useEffect } from "react";
import $ from 'jquery';
import LogoSection from './LogoSection';
import NavbarSection from './NavbarSection';

const Header = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js";
        script.async = true;
        document.body.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js";
        script2.async = true;
        document.body.appendChild(script2);
      return () => {
          document.body.removeChild(script);
          document.body.removeChild(script2);
        }
      }, []);

    useEffect(() => {
        var view_width = $(window).width();
        if (!$('header').hasClass('gen-header-default') && view_width >= 1023)
        {
            var height = $('header').height();
            $('.gen-breadcrumb').css('padding-top', height * 1.3);
        }
        if ($('header').hasClass('gen-header-default'))
        {
            $(window).scroll(function() {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > 300) {
                    $('.gen-bottom-header').addClass('gen-header-sticky animated fadeInDown animate__faster');
                } else {
                    $('.gen-bottom-header').removeClass('gen-header-sticky animated fadeInDown animate__faster');
                }
            });
        }
        if ($('header').hasClass('gen-has-sticky')) {
            $(window).scroll(function() {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > 300) {
                    $('header').addClass('gen-header-sticky animated fadeInDown animate__faster');
                } else {
                    $('header').removeClass('gen-header-sticky animated fadeInDown animate__faster');
                }
            });
        }
    }, [])
    

    return (
        <header id="gen-header" className="gen-header-style-1 gen-has-sticky">
            <div className="gen-bottom-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <LogoSection />
                                <NavbarSection />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
