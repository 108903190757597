import { useContext, useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import LoaderSpinner from 'utils/LoaderSpinner';
import GridItem from './GridItem';
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../contexts/AppContext";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import useAxiosSearch from "../../hooks/useAxiosSearch";

/** MUESTRA LOS RESULTADOS DE LA BUSQUEDA **/

const SearchView = ({ path = "" }) => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/search/${path}`, title: `Wellbeing360 - SEARCH-${path}` });
        ReactPixel.track(`SEARCH-${path}`);
    }, []);

    const dataLang = useContext(AppContext)
    console.log(path);
    let navigate = useNavigate();
    const [search_data, set_search_data] = useState('')
    const [searchResults, setSearchResults] = useState([]);
    const [isEmptySearchResults, setIsEmptySearchResults] = useState(true);

    const { response, loading, error } = useAxiosSearch({
        url: 'search',
        method: 'post',
        body: JSON.stringify(`{"query": "${path}"}`)
    });


    const onInputChange = (event) => {
        event.preventDefault();
        set_search_data(event.target.value)
    }

    useEffect(() => {
        let list = []
        if (response !== null) {
            console.log(response)
            for (const video of response) {
                if (video.public_see === 1) {
                    list.push(video)
                }
            }
            setSearchResults(list);
            setIsEmptySearchResults(false);
        }
    }, [response]);

    const SendData = (event) => {
        event.preventDefault();
        const path = search_data
        set_search_data('')
        console.log(path);
        if (path.length > 0) {
            navigate(`/search/${path}`)
        }
    }

    const Grid = () => {
        if (searchResults.length === 0) {
            return (
                <div className="container">
                    <h1 className="content-resultless"><FormattedMessage
                        id="searchDefault"
                        defaultMessage="No se han encontrado resultados"
                    /></h1>
                </div>
            )
        }
        const gridItems = searchResults.map((result) =>
            <GridItem key={result.stream_id} {...result} />
        );

        return (
            <section className="gen-section-padding-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                {gridItems}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    if (!isEmptySearchResults) {
        return (
            <div>
                <div className="container row">
                    <div className="col-md-4"></div>
                    <form className="search-form col-md-6">
                        <input autoFocus type="text" className="search-field"
                            placeholder={dataLang.langSpanish ? "Buscar …" : 'Search …'}
                            autoComplete="off" value={search_data}
                            onChange={(event) => onInputChange(event)}
                            onSubmit={(event) => SendData(event)} />
                        <button type="submit" className="search-submit"
                            onClick={(event) => SendData(event)}>
                            <span className="screen-reader-text"></span></button>
                    </form>
                </div>
                <div>
                    <h3 className=" container general-search-dom">
                        <FormattedMessage
                            id="searchSearch"
                            defaultMessage="Busqueda"
                        />: {path}</h3>
                </div>
                <Grid />
            </div>
        )
    }

    return (<LoaderSpinner />)


}

export default SearchView;
