// routing
import Routes from './routes';
import {AWSCognitoProvider as AuthProvider} from 'contexts/AWSCognitoContext';
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import {AppProvider} from './contexts/AppProvider';

function App() {
    return (
        <AuthProvider>
            <AppProvider>
                <Routes/>
            </AppProvider>
        </AuthProvider>
    );
}

export default App;
