import LoginForm from "./LoginForm";
import backgroundLogin from "assets/images/background/assetLogin.jpg";
import {useEffect, useState} from "react";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";


const LoginPage = () => {

    const [liveEvent, setLiveEvent] = useState(false);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: `/login`, title: `Wellbeing 360 - LOGIN`});
        ReactPixel.track(`LOGIN`);
        const querystring = window.location.search
        const params = new URLSearchParams(querystring)
        setLiveEvent(params.get("live_event"))
    }, []);

    return (
        <section className="position-relative pb-0 gen-login">
            <div className="gen-login-page-background" style={{backgroundImage: `url(${backgroundLogin})`}}></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <LoginForm eventLive={liveEvent}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginPage;
