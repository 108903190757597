import logo from 'assets/images/logo-wellbeing360-Color-Blanco.png';
import logoICBI from 'assets/images/LogoICBI.png';

const WidgetLeft = () => {

    return (
        <div className="widget">
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={logo} className="gen-footer-logo" alt="Wellbeing 360"/>
                        </div>
                        <div className="col-md-6">
                            <img src={logoICBI} className="gen-footer-logo" alt="Wellbeing 360"/>
                        </div>
                    </div>
                    <ul className="social-link justify-content-center">
                        <li><a href="https://www.facebook.com/w360tecmilenio" target="_blank" className="facebook"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://www.instagram.com/w360tecmilenio/" target="_blank" className="facebook"><i className="fab fa-instagram"></i></a></li>
                        {/*<li><a href="#" className="facebook"><i className="fab fa-skype"></i></a></li>*/}
                        <li><a href="https://twitter.com/w360tecmilenio" target="_blank" className="facebook"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.tiktok.com/@w360tecmilenio" target="_blank" className="facebook"><i className="fab fa-tiktok"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default WidgetLeft;
