import useAuth from "../../hooks/useAuth";
import {useForm} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import {config} from "../../config";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../contexts/AppContext";
import ReactPixel from "react-facebook-pixel";

const RecoveryPassword = () => {

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: `/recovery-pass`, title: `Wellbeing360 - RECOVERY-PASSWORD`});
    }, []);

    const dataLang = useContext(AppContext)
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/recovery-pass`, title:`Wellbeing 360 - RECOVERY-PASSWORD` });
        ReactPixel.track(`RECOVERY-PASSWORD`);
    }, []);
    let navigate = useNavigate();
    const {forgotPassword, recoveryPassword} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isWaitingCode, setIsWaitingCode] = useState(true)
    const {register, handleSubmit, formState: {errors}} = useForm();

    const OnSubmit = async (data) => {

        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!emailRegex.test(data.userName)) {
            const error_lang = dataLang.langSpanish ? "* Email invalido" : "* Invalid email"
            setError(error_lang)
            return
        } else {
            setError(null)
        }
        setLoading(true);
        setError('');
        try {
            await forgotPassword(data.userName)
            setTimeout(() => {
                setLoading(false)
                setIsWaitingCode(false)
            }, config.login_modal_wait_time);
        } catch (err) {
            console.log(err)
        }
    }

    const RecoveryPassword = async (data) => {
        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!emailRegex.test(data.userName)) {
            const error_lang = dataLang.langSpanish ? "* Email invalido" : "* Invalid email"
            setError(error_lang)
            return
        } else {
            setError(null)
        }
        if (data.password.length < 6) {
            const error_lang = dataLang.langSpanish ? "* La contraseña debe tener minimo 6 caracteres " : "* The password must be at least six characters long"
            setError(error_lang)
            toast(error_lang)
            return
        } else {
            setError(null)
        }
        if (data.password !== data.password2) {
            const error_lang = dataLang.langSpanish ? "* Password no coincide" : "* Password does not match"
            setError(error_lang)
            toast(error_lang)
            return
        } else {
            setError(null)
        }
        setLoading(true);
        setError('');
        try {
            await recoveryPassword(data.userName, data.verificationCode.trim(), data.password)
            setTimeout(() => {
                setLoading(false)
                const message_lang = dataLang.langSpanish ? 'Contraseña actualizada' :
                    "Your password was updated"
                toast(
                    (t) => (
                        <div className="">
                            <h4 className="pToastLogOut" >{message_lang}</h4>
                            <button className="btn button-purple buttonToast" onClick={() => {
                                toast.dismiss(t.id);
                                navigate(`/login`)
                            }}>Ok</button>
                        </div>
                    ), {duration: 10000,}
                );
            }, config.login_modal_wait_time);
        } catch (err) {
            setLoading(false)
            console.log(err)
            const message_lang = dataLang.langSpanish ? 'Ocurrio un error al actualizar la contraseña \n' +
                'Por favor verifica tu código' :
                "An error occurred while updating the password\nPlease check your code"
            if(err == "CodeMismatchException: Invalid verification code provided, please try again."){
                console.log("Codigo incorrecto")
                toast(
                    (t) => (
                        <div className="">
                            <h4 className="pToastLogOut" >{message_lang}
                            </h4>

                            <button className="btn button-purple buttonToast" onClick={() => {
                                toast.dismiss(t.id);
                            }}>Ok</button>
                        </div>
                    ), {duration: 10000,}
                );
            }
            if(err == 'ExpiredCodeException: Invalid code provided, please request a code again.'){
                console.log("codigo expirado")
                const message_lang = dataLang.langSpanish ? 'Ocurrio un error al actualizar la contraseña \n' +
                    'Por favor solicita un nuevo código' :
                    "An error occurred while updating the password\nPlease request a new code"
                setIsWaitingCode(true)
                toast(
                    (t) => (
                        <div className="">
                            <h4 className="pToastLogOut" >{message_lang}
                            </h4>

                            <button className="btn button-purple buttonToast" onClick={() => {
                                toast.dismiss(t.id);
                            }}>Ok</button>
                        </div>
                    ), {duration: 10000,}
                );
            }

        }
    }

    return (
        <>
        { isWaitingCode ? (
                    <section className="position-relative pb-10">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <form id="pms_recover_password_form" className="pms-form"
                                              onSubmit={handleSubmit(OnSubmit)}>
                                            <h4><FormattedMessage
                                                id="recoveryPasswordTitle"
                                                defaultMessage="Recuperar Contraseña"
                                            /></h4>

                                            <p className="font-weight-bold">
                                                <FormattedMessage
                                                    id="recoveryPasswordDescription"
                                                    defaultMessage="Por favor ingresa tu correo electrónico\nRecibiras un correo con información para restablecer tu contraseña"
                                                />
                                            </p>
                                            <ul className="pms-form-fields-wrapper pl-0 mb-4">
                                                <li className="pms-field">
                                                    <label htmlFor="pms_username_email">
                                                        <FormattedMessage
                                                        id="loginEmail"
                                                        defaultMessage="Correo electrónico"
                                                    /></label>
                                                    <input  {...register("userName", {required: true})}
                                                            id="pms_username_email" type="text"
                                                            defaultValue=""/>
                                                </li>
                                                <li className="pms-field pms-use pms-field pms-field-subscriptions">
                                                    {error ? (
                                                        <div className="login-error">
                                                            {error}
                                                        </div>
                                                    ) : null
                                                    }
                                                </li>
                                            </ul>
                                            {loading ?
                                                (<div className="spinner-border text-light" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>) :
                                                ( <button type="submit" onSubmit={handleSubmit(OnSubmit)}>
                                                    <FormattedMessage
                                                        id="recoveryPassSendMail"
                                                        defaultMessage="Enviar Correo"
                                                    /></button> )}

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>) :
                (<section className="position-relative pb-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <form id="pms_recover_password_form" className="pms-form" autoComplete="off"
                                          onSubmit={handleSubmit(RecoveryPassword)}>
                                        <h4><FormattedMessage
                                            id="recoveryPasswordTitle"
                                            defaultMessage="Recuperar Contraseña"
                                        /></h4>

                                        <p className="font-weight-bold">
                                            <FormattedMessage
                                                id="recoveryPassNewData"
                                                defaultMessage="Ingrese los nuevos datos"
                                            />

                                        </p>
                                        <ul className="pms-form-fields-wrapper pl-0 mb-4">
                                            <li className="pms-field">
                                                <label htmlFor="pms_username_email">
                                                    <FormattedMessage
                                                        id="loginEmail"
                                                        defaultMessage="Correo electrónico"
                                                    />
                                                </label>
                                                <input  {...register("userName", {required: true})}
                                                        id="pms_username_email" type="text"
                                                        defaultValue=""/>
                                            </li>
                                            <li className="pms-field">
                                                <label htmlFor="pms_username_email"><FormattedMessage
                                                    id="checkCode"
                                                    defaultMessage="Codigo de Verificación"
                                                /></label>
                                                <input  {...register("verificationCode", {required: true})}
                                                        id="pms_username_email" type="text"
                                                        defaultValue=""/>
                                            </li>
                                            <li className="pms-field">
                                                <label htmlFor="pms_username_email">
                                                    <FormattedMessage
                                                        id="loginNewPassword"
                                                        defaultMessage="Nueva Contraseña"
                                                    /></label>
                                                <input  {...register("password", {required: true})}
                                                        id="pms_username_email" type="password"
                                                        defaultValue=""/>
                                            </li>
                                            <li className="pms-field">
                                                <label htmlFor="pms_username_email">
                                                    <FormattedMessage
                                                        id="loginNewPassword"
                                                        defaultMessage="Nueva Contraseña"
                                                    /></label>
                                                <input  {...register("password2", {required: true})}
                                                        id="pms_username_email" type="password"
                                                        defaultValue=""/>
                                            </li>
                                            <li className="pms-field pms-use pms-field pms-field-subscriptions">
                                                {error ? (
                                                    <div className="login-error">
                                                        {error}
                                                    </div>
                                                ) : null
                                                }
                                            </li>
                                        </ul>
                                        {loading ?
                                            (<div className="spinner-border text-light" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>) :
                                            ( <button type="submit" onSubmit={handleSubmit(RecoveryPassword)}>
                                                <FormattedMessage
                                                    id="loginUpdateTitle"
                                                    defaultMessage="Actualizar Contraseña"
                                                /></button> )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>)
        }
        </>
    )
}

export default RecoveryPassword
