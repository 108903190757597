import {useEffect, useState} from 'react';
import useAxios from 'hooks/useAxios';
import CarouselItem from './CarouselItem';
import LoaderSpinner from 'utils/LoaderSpinner';
import SlideItem from "../Carousels/SlideItem";
import OwlCarousel from "react-owl-carousel";
import {config} from "../../../config";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

/** MUESTRA TODOS LOS VIDEOS AGRUPADOS POR CATEGORIA LA CATEGORIA RECIBIDA**/

const Carousels = ({category_id, category_name}) => {

    const [vodCategories, setVodCategories] = useState([]);
    const [showContent, setShowContent] = useState(true)
    const {response, loading, error} = useAxios({
        method: 'get',
        url: `aos-vod/category/${category_id}?tenant_id=tecmilenio&category_id=${category_id}`
    });

    useEffect(() => {
        let list = []
        if (response !== null) {
            if (response.category_list.length > 0) {
                for (const video of response.category_list) {
                    if (video.public_see === 1) {
                        list.push(video)
                    }
                }
            } else {
                setShowContent(false)
            }
            setVodCategories(list);
        }
    }, [response]);

    const Slider = () => {

        const Slides = vodCategories.map((vod) =>

            <SlideItem key={vod.stream_id} {...vod} />
        );

        return (
            <OwlCarousel className='owl-carousel owl-loaded owl-drag' {...config.owlCarousel.carousel.options}>
                {Slides}
            </OwlCarousel>
        )
    }

    return (
        <>
            {loading ? (
                <LoaderSpinner/>
            ) : (
                <>
                    {showContent ? <section className="pt-0 pb-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <h4 className="gen-heading-title text-uppercase">
                                        <FormattedMessage
                                            id={category_name}
                                            defaultMessage="DEFAULT"
                                        />
                                    </h4>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block">
                                    <div className="gen-movie-action">
                                        <div className="gen-btn-container text-right">
                                            <Link to={`/category/${category_id}`}
                                                  className="gen-button gen-button-flat">
                                        <span className="text">
                                            <FormattedMessage
                                                id="homeDiscoverMore"
                                                defaultMessage="DESCUBRE MÁS"
                                            />
                                        </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="gen-style-2">
                                        {loading ? (<LoaderSpinner/>) : (<Slider/>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> : null}
                </>
            )}
        </>

    )
}

/*
*/

export default Carousels;
