const initialState = {
    isOpen: false
};

const modalWindowReducer = (state = initialState, action) => {
    switch (action.type) {
        case '[MODAL] Open Modal': {
            return {
                ...state,
                isOpen: true
            };
        }
        case '[MODAL] Close Modal': {
            return {
                ...state,
                isOpen: false
            };
        }
        default: {
            return state;
        }
    }
};

export default modalWindowReducer;
