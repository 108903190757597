import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import useAxios from 'hooks/useAxios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TeaserVodBody from './TeaserVodBody';
import LoaderSpinner from 'utils/LoaderSpinner';
import { config } from 'config';
import ReactGA from "react-ga4";
import {FormattedMessage} from "react-intl";
import ReactPixel from "react-facebook-pixel";

const VodTeaser = ({id}) => {

    const [vodItem, setVodItem] = useState([]);
    const [isEmptyVodItem, setIsEmptyVodItem] = useState(true);

    const { response, loading, error } = useAxios({
        method: 'get',
        url: `/aos-vod?stream_id=${id}&tenant_id=${config.tenant_id}`
    });

    useEffect(() => {
        if (response !== null) {
            setVodItem(response.stream);
            setIsEmptyVodItem(false);
            ReactGA.send({ hitType: "pageview", page: `/teaser/vod/${id}`, title:`Wellbeing 360 - VOD_${response.stream.title}` });
            ReactPixel.track(`VOD_${response.stream.title}`);
        }
    }, [response]);

    const Slider = () => {

        return (
            <OwlCarousel className='owl-carousel owl-loaded owl-drag' {...config.owlCarousel.simple_teaser.options}>
                 <TeaserVodBody key={vodItem.asset_id} {...vodItem} />
            </OwlCarousel>
        )
    }

    return (
        <>
            {(isEmptyVodItem) ? (
                <LoaderSpinner />
            ) :  (
                <>
                    <div id="bitmovin-player-container" className="gen-btn-container button-1">
                        <div className='row my-4 pl-3'>
                            <Link to="/" className="gen-button small">
                                <i aria-hidden="true" className="ion ion-arrow-return-left"></i>
                                <span className="text">
                                    <FormattedMessage
                                        id="buttonGoBack"
                                        defaultMessage="Regresar"
                                    /></span>
                            </Link>
                        </div>
                    </div>
                    <section className="pt-0 pb-0">
                        <div className="container-fluid px-0">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="gen-banner-movies teaser-page">
                                        <Slider {...vodItem} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    )
}

export default VodTeaser;
