import {useState, useEffect, useContext} from "react";
import SearchView from "../SearchView/searchView";
import { useParams, } from "react-router-dom";
import Breadcrumb from 'utils/Breadcrumb';
import {AppContext} from "../../contexts/AppContext";

const SearchPage = () => {
    const dataLang = useContext(AppContext)

    const { data } = useParams();

    return (
            <div>
                <Breadcrumb title={ dataLang.langSpanish ? 'RESULTADOS' : 'RESULTS' } />
                <div className='mt-4'>
                    <SearchView path={data} />
                </div>
            </div>
        )


}

export default SearchPage;
