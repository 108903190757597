import {config} from 'config';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useContext, useState} from "react";
import {AppContext} from "../../../contexts/AppContext";

const SlideItem = ({stream_id, title, title_en, description, description_en, thumbnail, metadata}) => {
    const context = useContext(AppContext)

    return (
        <div className="item" style={{backgroundImage: `url(${config.cloudfront_url}/${encodeURI(thumbnail)})`}}>
            <div className="gen-movie-contain h-100">
                <div className="container h-100">
                    <div className="row align-items-center h-100">

                        <div className="col-xl-6">
                            <div className="gen-tag-line"><span></span></div>
                            <div className="gen-movie-info">
                                { context.langSpanish ? <h3>{title}</h3>: <h3>{title_en}</h3>}
                            </div>
                            <div className="gen-movie-meta-holder">
                                <ul>
                                    <li>
                                        {metadata[config.consts.YEAR]}
                                    </li>
                                </ul>
                                { context.langSpanish ? <p>{description}</p> : <p>{description_en}</p>}
                            </div>
                            <div className="gen-movie-action">
                                <div className="gen-btn-container button-1">
                                    <Link to={`/teaser/vod/${stream_id}`} className="gen-button"> 
                                        <span className="text">
                                            <FormattedMessage
                                                id="buttonWatchNow"
                                                defaultMessage="Ver Ahora"
                                            /></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideItem;
