import LoaderSpinner from 'utils/LoaderSpinner';
import {config} from "../../../config";
import {useContext, useEffect, useState} from "react";
import useAxios from "../../../hooks/useAxios";
import {Link} from "react-router-dom";
import $ from 'jquery';
import SlideVerticalItem from "./SlideVerticalItem";
import SlideItem from "./SlideItem";
import {FormattedMessage} from "react-intl";

function CarrouselSlick() {
    $('.slider-for').each(function () {
        $('.slider-for').not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
        });
    });
    $('.slider-nav').each(function () {
        var prev = 'ion-chevron-up';
        var next = 'ion-chevron-down';
        $('.slider-nav').not('.slick-initialized').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: true,
            vertical: true,
            focusOnSelect: true,
            centerMode: true,
            prevArrow: '<div class="prev"><span class="' + prev + '"></span></div>',
            nextArrow: '<div class="next"><span class="' + next + '"></span></div>',
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    adaptiveHeight: true,
                },
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },],
        });
    });
}

const SliderSlick = ({category_id, category_name_for_show}) => {
    const [vodCategories, setVodCategories] = useState([]);
    const [slickContext, setSlickContext] = useState({})
    const {response, loading, error} = useAxios({
        method: 'get',
        url: `aos-vod/category/${category_id}?tenant_id=tecmilenio&category_id=${category_id}`
    });
    useEffect(() => {
        const script2 = document.createElement('script');
        script2.src = "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js";
        script2.async = true;
        document.body.appendChild(script2);
        return () => {
            document.body.removeChild(script2);
        }
    }, []);

    useEffect(() => {
        if (response !== null) {
            let list = []
            for (const video of response.category_list) {
                if (video.public_see === 1) {
                    list.push(video)
                }
            }
            setVodCategories(list);
            setTimeout(() => CarrouselSlick(), 2500)
        }
    }, [response]);

    const Slider = () => {
        const Slides = vodCategories.map((vod) =>
            <SlideItem vod={vod}/>
        );
        return (Slides)
    }

    const SliderVertical = () => {
        const Slides = vodCategories.map((vod) =>
            <SlideVerticalItem vod={vod}/>
        );
        return (Slides)
    }

    const SliderHeader = () => {

        return (
            <div className="container space">
                <div className="col-xl-6 col-lg-6 col-md-6">
                    <h4 className="gen-heading-title text-uppercase">
                        <FormattedMessage
                            id={category_name_for_show}
                            defaultMessage="LO MÁS NUEVO"
                        />
                    </h4>
                </div>
            </div>
        )

    }

    return (
        <>
            <SliderHeader/>
            {loading ?
                <LoaderSpinner/> :
                <section className="gen-section-padding-2 pt-0 pb-0">
                    <div className="container">

                        <div className="home-singal-silder">
                            <div className="gen-nav-movies gen-banner-movies">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="slider slider-for">
                                            <Slider/>
                                        </div>
                                        <div className="slider slider-nav">
                                            <SliderVertical/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )


};

export default SliderSlick;
