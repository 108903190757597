import {useContext, useState} from 'react';
import useAuth from "hooks/useAuth";
import {useNavigate, useLocation} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {config} from 'config';
import logo from 'assets/images/LogoWellbeing360.png';
import toast from "react-hot-toast";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../../contexts/AppContext";


const LoginForm = ({handleCloseModal, eventLive}) => {
    const dataLang = useContext(AppContext)
    const {login, newPasswordChallenge, user} = useAuth();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [isWaitingForLogin, setIsWaitingForLogin] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [newPassRequired, setNewPassRequired] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [affiliate, setAffiliate] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = async (data) => {
        setIsWaitingForLogin(true);
        setErrorMessage('');
        try {
            await login(data.userName, data.password);
            setTimeout(() => {
                if (location.pathname === '/login') {
                    if (eventLive){
                        navigate(`/live/index.html?token=${user.session}`);
                    }
                    else{
                        navigate('/');
                    }
                } else {
                    handleCloseModal();
                }
                setIsWaitingForLogin(false);
            }, config.login_modal_wait_time);
        } catch (err) {
            setIsWaitingForLogin(false);
            if (err == 'NotAuthorizedException: Incorrect username or password.') {
                const error_lang = dataLang.langSpanish ? 'Correo o contraseña incorrecta.' : "Invalid email or password"
                setErrorMessage(error_lang);
            }
            if (err == 'NotAuthorizedException: Password attempts exceeded') {
                const error_lang = dataLang.langSpanish ? 'Ha excedido el número de intentos. Espere unos minutos e inténtelo nuevamente.' :
                    "The number of attempts has been displayed, wait a few minutes and try again"
                setErrorMessage(error_lang);
            }
            if (err == "newPasswordRequired") {
                setNewPassRequired(true)
                const message_lang = dataLang.langSpanish ? "Por favor actualiza tu contraseña" : "pleas update your password"
                toast(
                    (t) => (
                        <div className="">
                            <h4 className="pToastLogOut">{message_lang}</h4>
                            <button className="btn button-purple buttonToast" onClick={() => {
                                toast.dismiss(t.id);
                            }}>Ok
                            </button>
                        </div>
                    ), {duration: 5000,}
                );
            }
        }
    }

    const chalengeNewPassword = async (data) => {
        setIsWaiting(true);
        setErrorMessage('');
        try {
            await newPasswordChallenge(data.userName, data.password, data.newPassword);
            setTimeout(() => {
                setIsWaiting(false);
                const message_lang = dataLang.langSpanish ? 'Contraseña actualizada' :
                    "Your password was updated"
                if (location.pathname === '/login') {
                    toast(
                        (t) => (
                            <div className="">
                                <h4 className="pToastLogOut" >{message_lang}</h4>
                                <button className="btn button-purple buttonToast" onClick={() => {
                                    navigate(`/`)
                                    toast.dismiss(t.id);
                                }}>Ok</button>
                            </div>
                        ), {duration: 20000,}
                    );
                } else {
                    handleCloseModal();
                }
                setIsWaitingForLogin(false);
            }, config.login_modal_wait_time);
        } catch (err) {
            console.log(err)

            setIsWaiting(false);
            if (err == 'NotAuthorizedException: Incorrect username or password.') {
                const error_lang = dataLang.langSpanish ? 'Correo o contraseña incorrecta.' : "Invalid email or password"
                setErrorMessage(error_lang);
            }
            if (err == 'NotAuthorizedException: Password attempts exceeded') {
                const error_lang = dataLang.langSpanish ? 'Ha excedido el número de intentos. Espere unos minutos e inténtelo nuevamente.' :
                    "The number of attempts has been displayed, wait a few minutes and try again"
                setErrorMessage(error_lang);
            }
        }
    }

    const LoginForm = () => {
        return (
            <>
                <form name="pms_login" id="pms_login" className="colorBlack" autoComplete="off"
                      onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-9">
                            <h4><FormattedMessage
                                id="loginTitle"
                                defaultMessage="INICIAR SESIÓN"
                            /></h4>
                        </div>

                        <div className="col-md-3">
                            <Link to={'/'} className="navbar-brand">
                                <img src={logo} alt="Wellbeing 360" className="img-fluid logo"/>
                            </Link>
                        </div>
                    </div>
                    <p className="login-username">
                        <label>
                            <FormattedMessage
                                id="loginEmail"
                                defaultMessage="Correo electrónico"
                            /></label>
                        <input
                            {...register("userName", {required: true})}
                            type="text"
                            id="user_login"
                            className="input"
                            defaultValue=""
                            size="20"/>
                        {errors?.userName?.type === "required" && <span className="form-error">
                            <FormattedMessage
                                id="loginRequiredCamp"
                                defaultMessage="Campo requerido"
                            /></span>}
                    </p>
                    <p className="login-password">
                        <label><FormattedMessage
                            id="loginPassword"
                            defaultMessage="Contraseña"
                        /></label>
                        <input
                            {...register("password", {required: true})}
                            type="password"
                            id="user_pass"
                            className="input"
                            defaultValue=""
                            size="20"/>
                        {errors?.password?.type === "required" && <span className="form-error">
                            <FormattedMessage
                            id="loginRequiredCamp"
                            defaultMessage="Campo requerido"
                        /></span>}
                    </p>
                    {errorMessage ? (
                        <div className="login-error">
                            {errorMessage}
                        </div>
                    ) : <></>
                    }
                    <div className="login-submit">
                        {isWaitingForLogin ? (
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            <input type="submit" name="wp-submit" id="wp-submit" className="button button-primary"
                                   value="Log In"/>
                        )
                        }
                    </div>
                    <p>
                        <input type="hidden" name="pms_login" value="1"/><input type="hidden" name="pms_redirect"/>
                        <a
                            href="/sign-up"><FormattedMessage
                            id="subscribeLow"
                            defaultMessage="Suscríbete"
                        />
                        </a> |
                        <a href="/recovery-pass"><FormattedMessage
                            id="loginForgotPassword"
                            defaultMessage=" ¿Olvidaste tu contraseña?"
                        />
                        </a>
                    </p>
                </form>
            </>
        )
    }

    const NewPass = () => {
        return (
            <>
                <form name="pms_login" id="pms_login" className="colorBlack" autoComplete="off"
                      onSubmit={handleSubmit(chalengeNewPassword)}>
                    <div className="row">
                        <div className="col-md-9">
                            <h4><FormattedMessage
                                id="loginUpdateTitle"
                                defaultMessage="ACTUALIZAR CONTRSEÑA"
                            /></h4>
                        </div>

                        <div className="col-md-3">
                            <Link to={'/'} className="navbar-brand">
                                <img src={logo} alt="Wellbeing 360" className="img-fluid logo"/>
                            </Link>
                        </div>
                    </div>
                    <p className="login-username">
                        <label><FormattedMessage
                            id="loginEmail"
                            defaultMessage="Correo electrónico"
                        /></label>
                        <input
                            {...register("userName", {required: true})}
                            type="text"
                            id="user_login"
                            className="input"
                            defaultValue=""
                            size="20"/>
                        {errors?.userName?.type === "required" && <span className="form-error"><FormattedMessage
                            id="loginRequiredCamp"
                            defaultMessage="Campo requerido"
                        /></span>}
                    </p>
                    <p className="login-password">
                        <label><FormattedMessage
                            id="loginPassword"
                            defaultMessage="Contraseña"
                        /></label>
                        <input
                            {...register("password", {required: true})}
                            type="password"
                            id="user_new_pass"
                            className="input"
                            defaultValue=""
                            size="20"/>
                        {errors?.password?.type === "required" && <span className="form-error"><FormattedMessage
                            id="loginRequiredCamp"
                            defaultMessage="Campo requerido"
                        /></span>}
                    </p>
                    <p className="login-password">
                        <label><FormattedMessage
                            id="loginNewPassword"
                            defaultMessage="Nueva Contraseña"
                        /></label>
                        <input
                            {...register("newPassword", {required: true})}
                            type="password"
                            id="user_pass"
                            className="input"
                            defaultValue=""
                            size="20"/>
                        {errors?.password?.type === "required" && <span className="form-error"><FormattedMessage
                            id="loginRequiredCamp"
                            defaultMessage="Campo requerido"
                        /></span>}
                    </p>
                    {errorMessage ? (
                        <div className="login-error">
                            {errorMessage}
                        </div>
                    ) : <></>
                    }
                    <div className="login-submit">
                        {isWaiting ? (
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            <input type="submit" name="wp-submit" id="wp-submit" className="button button-primary"
                                   value={ dataLang.langSpanish ? "Actualizar" : "Update"}/>
                        )
                        }
                    </div>
                </form>
            </>
        )
    }

    return (
        <>
            {newPassRequired ? (<NewPass/>) : (<LoginForm/>)}
        </>
    )
}

export default LoginForm;
