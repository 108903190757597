import {NavLink} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import useAuth from "hooks/useAuth";
import $ from "jquery";
import AxiosItem from "./AxiosItem";
import SignUpItem from "./SignUpItem";
import EventLive from "./EventItem";
import SearchItem from "./SearchItem";
import {FormattedMessage} from "react-intl";
import {AppContext} from "../../../../contexts/AppContext";
import {EventButton} from "../../../../utils/EventButton";

const MenuItems = () => {
    const {user} = useAuth();
    const [isLoggedInUser, setLoggedInUser] = useState(false);
    const context = useContext(AppContext);

    useEffect(() => {
        if (user !== null) {
            setLoggedInUser(true);
            return;
        }
        setLoggedInUser(false);
    }, [user]);
    // const [desktopScreen, setDesktopScreen] = useState(false);
    // let view_width = $(window).width();
    //
    // useEffect(() => {
    //         if (view_width <= 1023) {
    //             setDesktopScreen(true)
    //         } else {
    //             setDesktopScreen(false)
    //         }
    //     },
    //     [view_width]
    // );

    return (
        <div id="gen-menu-contain" className="gen-menu-contain">
            <ul id="gen-main-menu" className="navbar-nav ml-auto">
                
            

                <li className="menu-item">
                    <EventButton/>
                </li>

                <li className="d-lg-none">
                    <NavLink
                        className='button-purple'
                        to='/sign-up'
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent">
                        <FormattedMessage
                            id="subscribeLow"
                            defaultMessage="Suscríbete"
                        />
                    </NavLink>
                </li>

                <li className="menu-item">
                    <AxiosItem/>
                </li>
                <li className="menu-item">
                    <NavLink
                        to='category/7e9ac6dc5322422e802710f2c489f44a'
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent">
                        <FormattedMessage
                            id="navNewest"
                            defaultMessage="Lo + nuevo default"
                        />
                    </NavLink>
                </li>
                <EventLive/>
         

                <SearchItem />

                <li className="menu-item">
                    <SignUpItem/>
                </li>

                <li className="d-none d-lg-block">
                    <NavLink
                        className='button-purple'
                        to='/sign-up'
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent">
                        <FormattedMessage
                            id="SubscribeUp"
                            defaultMessage="SUSCRíBETE"
                        />
                    </NavLink>
                </li>

                <div>
                    <div className="btn-group dropdown d-none d-lg-block">
                        <button type="button" className="btn dropdown-toggle button_langs" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton">
                            <i className="fa fa-globe fa-2x"></i>
                        </button>
                        <div className="dropdown-menu" id="dropdown-menu" key={"dropdown-menu-1234"}
                             aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" onClick={() => context.ChangeLang("es-MX")}>Español</a>
                            <a className="dropdown-item" onClick={() => context.ChangeLang("en-US")}>English</a>
                        </div>
                    </div>
                    <div className="d-lg-none">
                        <a className="lang-margin-mobile" onClick={() => context.ChangeLang("es-MX")}>Español</a>

                    </div>
                    <div className="d-lg-none">
                        <a className="lang-margin-mobile" onClick={() => context.ChangeLang("en-US")}>English</a>
                    </div>
                </div>

            </ul>
        </div>
    )
}

export default MenuItems;
