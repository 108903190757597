import {config} from 'config';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useContext} from "react";
import {AppContext} from "../../../contexts/AppContext";

const SlideItem = ({vod}) => {
    const dataLang = useContext(AppContext)

    return (
        <div className="slider-item" key={`full${vod.stream_id}`}
             style={{backgroundImage: `url(${config.cloudfront_url}/${encodeURI(vod.thumbnail)})`}}>
            <div className="gen-slick-slider h-100">
                <div className="gen-movie-contain h-100">
                    <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-lg-6">
                                <div className="gen-movie-info">
                                    {dataLang.langSpanish ? <h3>{vod.title}</h3> : <h3>{vod.title_en}</h3>}
                                    {dataLang.langSpanish ? <p>{vod.description}</p> : <p>{vod.description_en}</p>}

                                </div>
                                <div className="gen-movie-action">
                                    <div className="gen-btn-container button-1">
                                        <Link to={`/teaser/vod/${vod.stream_id}`} className="gen-button">
                                            <span className="text">
                                                <FormattedMessage
                                                    id="buttonWatchNow"
                                                    defaultMessage="Ver Ahora"
                                                />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideItem;
