import WidgetLeft from "./WidgetLeft";
import WidgetCenter from "./WidgetCenter";
import WidgetCenterRight from "./WidgetCenterRight";
import WidgetRight from "./WidgetRight";
import {FormattedMessage} from "react-intl";

const Footer = () => {

    return (
        <footer id="gen-footer">
            <div className="gen-footer-style-1">
                <div className="gen-footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-md-6">
                                <WidgetLeft />
                            </div>
                            {/*<div className="col-xl-4 col-md-6">*/}
                            {/*    <WidgetCenter />*/}
                            {/*</div>*/}
                            <div className="col-xl-4 col-md-6">
                                <WidgetCenterRight />
                            </div>
                            {/*<div className="col-xl-3 col-md-6">*/}
                            {/*    <WidgetRight />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="gen-copyright-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 align-self-center">
                                <span className="gen-copyright"><a target="_blank" href="#">
                                    <FormattedMessage
                                    id="footerRights"
                                    defaultMessage="Todos los derechos reservados Tecmilenio 2022."
                                /></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer;
