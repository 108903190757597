import React from 'react';
import { Player, ViewMode } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';

class BitmovinPlayer extends React.Component {
    
    state = {
        player: null,
        playerConfig: this.props.playerConfig.playerConfig,
        playerSource: {
            hls: this.props.playerConfig.playerSource.hls,
            poster: this.props.playerConfig.playerSource.poster
        }
    };

    constructor(props) {
        super(props);
        this.playerDiv = React.createRef();
    }

    componentDidMount() {
        this.setupPlayer();
    }

    componentWillUnmount() {
        this.destroyPlayer();
    }

    setupPlayer() {
        const player = new Player(this.playerDiv.current, this.state.playerConfig);
        UIFactory.buildDefaultUI(player);
        player.load(this.state.playerSource).then(() => {
            this.setState({
                ...this.state,
                player
            });
            if(this.props.playerConfig.viewMode === 'Fullscreen'){
                player.setViewMode(ViewMode.Fullscreen);
            }
            console.log('Successfully loaded source');
            if (this.props.playerConfig.subtitles != "" && this.props.playerConfig.subtitles != undefined){
                const esSubtitle = {
                    id: "sub1",
                    lang: "es",
                    label: "Español",
                    url: this.props.playerConfig.subtitles,
                    kind: "subtitle"
                };
                player.subtitles.add(esSubtitle);
                console.log(this.props.playerConfig.subtitles)
            }
        }, () => {
            console.log('Error while loading source');
        });
    }

    destroyPlayer() {
        if (this.state.player != null) {
            this.state.player.destroy();
            this.setState({
                ...this.state,
                player: null
            });
        }
    }

    render() {
        return <div id='player' ref={this.playerDiv}/>;
    }
}

export default BitmovinPlayer;
