import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';


const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    console.log('GuestGuard')
    useEffect(() => {
        if (isLoggedIn) {
            navigate('', { replace: true });
        }
    }, [isLoggedIn, navigate]);
    
    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
