import {Link} from "react-router-dom";
import {BackgroundImage} from "react-image-and-background-image-fade";
import backgroundVertical from "../../../assets/images/PruebaVertical.jpeg";
import {config} from 'config';
import {useContext} from "react";
import {AppContext} from "../../../contexts/AppContext";
import {FormattedMessage} from "react-intl";

const SlideItem = ({category_id, category_name, image_path_vertical, description}) => {
    const dataLang = useContext(AppContext)
    console.log(`${config.cloudfront_image_category}/${encodeURI(image_path_vertical)}`)
    return (
        <div className="">
            <div className="">
                <div className="gen-carousel-movies-style-4 movie-grid style-3">
                    <div className="gen-movie-contain">
                        <div className="gen-movie-img-vertical">
                            <Link to={`/category/${category_id}`}>
                                <BackgroundImage
                                    src={`${config.cloudfront_image_category}/${encodeURI(image_path_vertical)}`}
                                    className="gen-movie-image-vertical"
                                    lazyLoad
                                />
                            </Link>
                            <div className="gen-movie-action-title">
                                <h3>
                                    <Link
                                        to={`/category/${category_id}`}>{dataLang.langSpanish ? category_name : description}</Link>
                                </h3>
                            </div>
                            <div className="gen-movie-action">
                                <div className="gen-btn-container button-1">
                                    <Link to={`/category/${category_id}`} className="gen-button">
                                        <span className="text">
                                            <FormattedMessage
                                                id="buttonGallery"
                                                defaultMessage="Ir a Galería"
                                            /></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideItem;
