import { useEffect, useState, useContext } from 'react';
import useAuth from "hooks/useAuth";
import { AppContext } from "contexts/AppContext";
import {FormattedMessage} from "react-intl";

export const PlayButton = ({button_style, stream_id, handlerPlayer, handlerShowLivePlayer}) => {
    
    const { user }  = useAuth();
    const [isLoggedInUser, setLoggedInUser] = useState(false);
    const { modalLoginWindow, dispatchModalWindow } = useContext( AppContext );

    useEffect(() => {
        if (user !== null) {
            setLoggedInUser(true);
            return;    
        }
        setLoggedInUser(false);    
    }, [user]);

    const handlePlayWithoutLogin = () => {
        const action = {
            type: '[MODAL] Open Modal',
            payload: ''
        }
        dispatchModalWindow(action)
    }

    const PlayCircleButtonWithLogin = () => {
        return (
            <a onClick={(event) => handlerPlayer(stream_id)} className="gen-button">
                <i className="fa fa-play"></i>
            </a>
        )
    }

    const PlayCircleButtonWithoutLogin = () => {
        return (
            <a onClick={handlePlayWithoutLogin} className="gen-button">
                <i className="fa fa-play"></i>
            </a>
        )
    }

    const PlayRectangleWithLogin = () => {
        return (
            <a onClick={handlerShowLivePlayer} className="gen-button"> 
                <span className="text">
                    <FormattedMessage
                        id="buttonWatchNow"
                        defaultMessage="Ver Ahora"
                    /></span>
            </a>
        )
    }

    const PlayRectangleWithoutLogin = () => {
        return (
            <a onClick={handlePlayWithoutLogin} className="gen-button"> 
                <span className="text"><FormattedMessage
                    id="buttonWatchNow"
                    defaultMessage="Ver Ahora"
                /></span>
            </a>
        )
    }

    const PlayButton = () => {
        if(button_style === 'circle') {
           return isLoggedInUser ? <PlayCircleButtonWithLogin/> : <PlayCircleButtonWithoutLogin />;
        }
        if(button_style === 'rectangle') {
            return isLoggedInUser ? <PlayRectangleWithLogin /> : <PlayRectangleWithoutLogin />;
        }
     }

    return (
        <>
            <PlayButton />
        </>
    )
}
