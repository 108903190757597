import {useEffect, useState, useContext} from 'react';
import useAuth from "hooks/useAuth";
import {AppContext} from "contexts/AppContext";
import {FormattedMessage} from "react-intl";

export const EventButton = () => {

    const {user} = useAuth();
    const [isLoggedInUser, setLoggedInUser] = useState(false);
    const [session, setSession] = useState("")
    const {modalLoginWindow, dispatchModalWindow} = useContext(AppContext);

    useEffect(() => {
        if (user !== null) {
            setLoggedInUser(true);
            setSession(user.session)
            return;
        }
        setLoggedInUser(false);
        setSession("")
    }, [user]);

    const handlePlayWithoutLogin = () => {
        const action = {
            type: '[MODAL] Open Modal',
            payload: ''
        }
        dispatchModalWindow(action)
    }

    const PlayRectangleWithLogin = () => {
        const date_now = new Date().getTime()
        console.log(date_now)
        if (1711040400000 > date_now && date_now > 1711036800000) {
            return (
                <a  className="btn btn-danger" href={`https://www.wellbeing360.tv/live/index.html?token=${session}`}>
                    <FormattedMessage
                        id="navEventLive"
                        defaultMessage="EVENTO"
                    />
                </a>
            )
        }else{
            return (
                <a href={`https://www.wellbeing360.tv/live/index.html?token=${session}`}>
                    <FormattedMessage
                        id="navEvent"
                        defaultMessage="EVENTO"
                    />
                </a>
            )

        }
    }

    const PlayRectangleWithoutLogin = () => {
        const date_now = new Date().getTime()
        console.log(date_now)
        if (1711040400000 > date_now && date_now > 1711036800000) {
            return (
                <a className="btn btn-danger" onClick={handlePlayWithoutLogin}>
                    <FormattedMessage
                        id="navEventLive"
                        defaultMessage="EVENTO"
                    />
                </a>
            )
        }else{
            return (
                <a onClick={handlePlayWithoutLogin}>
                    <FormattedMessage
                        id="navEvent"
                        defaultMessage="EVENTO"
                    />
                </a>
            )

        }
    }

    const EventButton = () => {
        return isLoggedInUser ? <PlayRectangleWithLogin/> : <PlayRectangleWithoutLogin/>;
    }

    return (
        <>
            <PlayRectangleWithLogin/>
        </>
    )
}
