import {useState, useEffect, useRef} from "react";
import useAuth from "hooks/useAuth";
import {NavLink, useNavigate} from "react-router-dom";
import $ from 'jquery';
import {FormattedMessage} from "react-intl";


const SearchItem = () => {

    const {user} = useAuth();
    const [isLoggedInUser, setLoggedInUser] = useState(false);


    useEffect(() => {
        if (user !== null) {
            setLoggedInUser(true);
            return;
        }
        setLoggedInUser(false);
    }, [user]);

    let navigate = useNavigate();
    const [search_data, set_search_data] = useState('')

    const [desktopScreen, setDesktopScreen] = useState(false);
    let view_width = $(window).width();

    useEffect(() => {
            if (!$('header').hasClass('gen-header-default') && view_width >= 1023) {
                setDesktopScreen(true)
            } else {
                setDesktopScreen(false)
            }
        },
        [view_width]
    );

    const sendData = (event) => {
        event.preventDefault();
        const path = search_data
        set_search_data('')
        console.log(path);
        if (path.length > 0) {
            navigate(`/search/${path}`)
        }
    }
    const onInputChange = (event) => {
        event.preventDefault();
        set_search_data(event.target.value)
    }

    const inputName = useRef(null);

    $('#dropdownButton').click(function () {
        console.log("click")
        inputName.current.focus();

    })

    return (
        <>
            <div className="dropdown show gen-menu-search-block d-none d-lg-block">
                <a href="" role="button" id="dropdownButton" data-toggle="dropdown" className="margin-search-dropdown"
                   aria-haspopup="true">
                    <i className="fa fa-search"></i>
                </a>

                <div className="dropdown-menu" id="myDropdown" aria-labelledby="dropdownMenuLink">
                    <form role="search" method="get" className="search-form" action="#">
                        <input autoFocus type="text" className="search-field"
                               onSubmit={(event) => sendData(event)}
                               placeholder="Buscar …" ref={inputName}
                               autoComplete="off" id="inputSearch" value={search_data}
                               onChange={(event) => onInputChange(event)}/>
                        <button type="submit" onClick={(event) => sendData(event)}
                                className="search-submit"><span
                            className="screen-reader-text"></span></button>
                    </form>
                </div>
            </div>

            <li className="menu-item d-lg-none menu-item">
                <NavLink
                    to='/search'
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"><FormattedMessage
                    id="navSearch"
                    defaultMessage="Busqueda"
                />
                </NavLink>
            </li>
        </>
    )

}

export default SearchItem;
